<template>
    <div :class="'mb-2 ' + [!login ? 'form-group' : '']">
        <label v-if="label" :for="id" :class="[login ? 'login-label' : '']+ ' ' + [error && !login ? 'danger-label' : '']">{{ label }}</label>
        
        <input
            v-if="!textarea"
            :id="id"
            :type="type"
            :class="'form-control base-input ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '']"
            :required="!!required"
            :value="modelValue"
            @input="updateInput"
            :maxlength="maxlength"
            :disabled="disabled == 1"
            v-maska="mask"
            :placeholder="placeholder"
        />

        <textarea v-else
            :id="id"
            :class="'form-control base-input ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '']"
            :required="!!required"
            :value="modelValue"
            @input="updateInput"
            rows="4"
            :placeholder="placeholder"
            :disabled="disabled == 1"
            :maxlength="maxlength"
        ></textarea>

        <span v-if="!!error && login" class="invalid-feedback error_login" :class="[error ? 'd-block' : '']" role="alert"><strong>{{ error }}</strong></span>

        <span v-if="!!error && !login" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:modelValue"],
    data() {
        return {
            global: useGlobalStore()
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        textarea: {
            type: Boolean,
            required: false,
            default: false,
        },

        modelValue: {
        },

        page: {
            type: String,
            required: true
        },

        login: {
            type: Boolean,
            required: false,
            default: false,
        },

        maxlength: {
            required: false,
            default:null,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        mask: {
            required: false,
            default:null,
        },

        placeholder: {
            required: false,
            default: null,
        },
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #545454;
}

.login-label {
    min-height: 21px;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
}

.base-input {
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 8px 15px;
    box-shadow: none;
    border: 1px solid #e2e2e2;
    font-size: 14px;
    color: #495057;
}

.login {
    border: 2px solid #e2e2e2;
}

.login:focus {
    outline: none !important;
    box-shadow: none;
    border: 2px solid #e2e2e2;
}

.error_login {
    font-size: 11px;
    margin-bottom: 15px;
}

.danger-border {
    border-color: #fd6d6d;
}

.danger-label {
    color: #d60e0e;
}

textarea {
    resize: none;
}
</style>

